@charset "UTF-8";

html {
  font-family: $font_12;
}

code {
  font-family: $font_12;
}

kbd {
  font-family: $font_12;
}

pre {
  font-family: $font_12;
}

samp {
  font-family: $font_12;
}

body {
  font-family: $font_2;
  .page-main {
    font-family: $font_2;
  }
  h1,
  h2,
  h3,
  h4 {
    font-family: $font_1;
  }
  h5,
  h6 {
    font-family: $font_3;
  }
  .headline {
    &--primary,
    &--large,
    &--secondary,
    &--medium,
    &--tertiary,
    &--quaternary {
      font-family: $font_1;
    }
  }
  .headline--quinary,
  .headline--senary {
    font-family: $font_3;
  }
  .headline--page,
  .headline--products {
    font-family: $font_1;
  }
  .headline--section {
    font-family: $font_1;
  }
  .headline--subsection {
    font-family: $font_2;
  }
  .pull-quote,
  blockquote {
    font-family: $font_1;
  }
  .text--bold,
  .text-small-header,
  .text--short,
  .text--short-copy,
  .text-short-body-copy,
  .text--tiny,
  .text--tiny-copy,
  .text--long-copy,
  .text-long-body-copy,
  .text--form-help,
  .text-form-submit {
    font-family: $font_2;
  }
  .text-primary-headlines,
  .text-medium-headlines,
  .text-product-names-main,
  .text-product-names-sub {
    font-family: $font_1;
  }
  label {
    font-family: $font_2;
  }
  p {
    font-family: $font_2;
  }
  .text--form-help p {
    font-family: $font_2;
  }
  .order-status-table__cell,
  .link,
  .link--bold,
  .section-head__link {
    font-family: $font_2;
  }
  .tabs.primary a,
  .tabs-component a {
    font-family: $font_2;
  }
  /* Page Navigation */
  .index-navigation,
  .page-navigation .depth-1 h3 {
    font-family: $font_3;
  }
  .page-utilities__help-text,
  .page-utilities__account-text,
  .page-utilities__signin-text,
  .page-utilities__cart-text,
  .utility-item-text,
  .page-navigation .level-1,
  .page-navigation .level-2,
  .page-navigation .level-3,
  .page-navigation__menu_text {
    font-family: $font_2;
  }
  .back-to-top {
    font-family: $font_2;
  }
  /* Promotions */
  .promo-unit__headline {
    font-family: $font_1;
  }
  .promo-unit__link {
    font-family: $font_2;
  }
  .promotions .nodeblock-signup-form ul {
    font-family: $font_2;
  }
  .email-and-sms-promotions {
    .overlay-addclass-privacy-policy-overlay {
      font-family: $font_1;
    }
  }
  /* Discover */
  .discover-more {
    .discover-more {
      &__header {
        font-family: $font_1;
      }
    }
  }
  .discover_more {
    &__product-header,
    &__product-sub-header {
      font-family: $font_1;
    }
  }
  /* Buttons */
  .button--dark,
  .button--light,
  .button--light-border,
  .btn,
  .cta,
  .continue-button-wrapper input[type='submit'],
  .checkout .submit input[type='submit'],
  .sample-select-button.selected,
  .viewcart-buttons .continue-checkout,
  .viewcart-buttons .go-shopping {
    font-family: $font_2;
  }
  /* Input Box */
  .text-form-text,
  input[type='text'],
  input[type='tel'],
  input[type='email'],
  input[type='password'],
  textarea,
  .form-text,
  select,
  a.selectBox,
  a.selectbox {
    font-family: $font_2;
  }
  .selectBox-options li a {
    font-family: $font_2;
  }
  .form-submit,
  .form-submit--light,
  .form-submit--selected,
  .form-submit--unselected,
  .form-submit--active,
  .form-submit--inactive {
    font-family: $font_2;
  }
  /* Checkout */
  .cart-item__product-name,
  .cart-item__product-subname,
  .cart-item__size {
    font-family: $font_3;
    @include iPhone_en;
  }
  .cart-item__qty,
  .cart-header-text,
  .cart-header,
  .cart-item__color,
  .cart-item__remove-form,
  .cart-item__price,
  .cart-item__total {
    font-family: $font_2;
  }
  .checkout__panel-title {
    font-family: $font_3;
    @include iPhone_en;
  }
  .checkout label {
    font-family: $font_2;
  }
  .checkout {
    .viewcart-header {
      .messages {
        font-family: $font_3;
      }
    }
    .sms-terms-and-conditions {
      a {
        font-family: $font_2;
      }
    }
  }
  .checkout-page-title {
    font-family: $font_1;
  }
  .checkout__subtitle {
    font-family: $font_2;
  }
  .checkout__header-item-count {
    font-family: $font_1;
  }
  .pc-place-order {
    font-family: $font_3;
  }
  .order-summary {
    &__subtotal-label,
    &__subtotal-value,
    &__shipping form,
    &__shipping-method,
    &__shipping-value,
    &__total-label,
    &__total-value {
      font-family: $font_2;
    }
  }
  .discount,
  .subtotal,
  .tax,
  .continue-shopping {
    font-family: $font_2;
  }
  .recommended-products__list > li {
    .product_name {
      font-family: $font_3;
    }
    .produce_subname {
      font-family: $font_3;
    }
  }
  .payment-book__item,
  .address-book__item,
  .payment-book__controls-link {
    font-family: $font_2;
  }
  .payment-info-small {
    .payment-info-small__card-info-label {
      font-family: $font_2;
    }
    .payment-info-small__address-header {
      font-family: $font_2;
    }
  }
  .payment-form .label,
  .payment-form__address-fieldset .choose-address label {
    font-family: $font_2;
  }
  .payment-display {
    h3 {
      font-family: $font_2;
    }
  }
  .giftwrap.label,
  .giftwrap.value,
  .gift-card__link {
    font-family: $font_2;
  }
  .giftcard-page {
    .giftcard-label {
      font-family: $font_2;
    }
    .giftcard-copy {
      font-family: $font_2;
      p {
        font-family: $font_2;
      }
    }
  }
  .gift-card-display {
    font-family: $font_2;
  }
  .review-submit__header {
    font-family: $font_2;
  }
  .review-submit__product-head {
    font-family: $font_1;
  }
  .guarantee-panel__title {
    font-family: $font_2;
  }
  .checkout__sidebar {
    h2 {
      font-family: $font_3;
    }
    .guarantee-panel__title,
    .links-panel__title {
      font-family: $font_2;
    }
  }
  .sidebar-menu {
    .sidebar-menu__link {
      font-family: $font_2;
    }
  }
  .sidebar-chat {
    .sidebar-chat__header {
      font-family: $font_1;
    }
    .sidebar-chat__link {
      font-family: $font_2;
    }
  }
  .confirmation-panel h3 {
    font-family: $font_1;
  }
  /* SPP , MPP */
  .spp-product__details .spp-product__details-header {
    font-family: $font_1;
  }
  .mpp__header {
    font-family: $font_1;
  }
  .mpp-compare__title,
  .mpp-compare__label,
  .mpp-compare__header,
  .mpp-compare__sub-header {
    font-family: $font_3;
  }
  .mpp-product-compare-nav {
    li.mpp-compare-button {
      a {
        font-family: $font_3;
      }
      .compare-counter-wrapper .compare-counter {
        font-family: $font_2;
      }
    }
  }
  .mpp-product-compare-tooltip {
    .compare-info {
      font-family: $font_3;
    }
    .mpp-compare-now-button {
      font-family: $font_2;
    }
  }
  .spp-product__details .spp-product__details-attribute__label,
  .product_brief__price,
  .product_brief__label2 {
    font-family: $font_2;
  }
  .text-product-anchor,
  .spp-product__anchor,
  .spp-product,
  .product-full {
    font-family: $font_2;
  }
  .text-product-listing-names-main,
  .text-product-listing-names-sub {
    font-family: $font_3;
    @include iPhone_en;
  }
  .product_brief__header,
  .product_brief__sub-header {
    font-family: $font_3;
    @include iPhone_en;
  }
  .text-index-navigation,
  .product-thumb .product-thumb__title {
    font-family: $font_3;
  }
  .text-benefits-line-main,
  .text-benefits-line-sub {
    font-family: $font_2;
  }
  .product-thumb .product-thumb__price {
    font-family: $font_2;
  }
  .spp-product__attribute h5,
  .product-full__attribute h5 {
    font-family: $font_2;
  }
  .spp-product__review,
  .spp-product__attribute p,
  .product-full__review,
  .product-full__attribute p {
    font-family: $font_2;
  }
  .spp-product__mini-bag-section {
    font-family: $font_3;
  }
  .spp-product__mini-bag-header {
    font-family: $font_3;
  }
  .spp-product__mini-bag-sub-header {
    font-family: $font_3;
  }
  .spp-product__mini-bag-price-size {
    font-family: $font_3;
  }
  .text-prices,
  .text-size,
  .text-promo,
  .text-links {
    font-family: $font_2;
  }
  .wishlist-confirm__button-close,
  .spp-product__detail-link,
  .spp-product__share,
  .spp-product__wishlist,
  .product-full__detail-link,
  .product-full__share,
  .product-full__wishlist {
    font-family: $font_2;
  }
  .text-batch-2-primary-headline,
  .text-batch-2-medium-headline {
    font-family: $font_1;
  }
  .text-batch-2-links {
    font-family: $font_2;
  }
  .product-hero-tout .tout__product-copy .cta-button-wrapper .button--plus-sign {
    font-family: $font_2;
  }
  .mpp__category-links--content {
    font-family: $font_1;
  }
  .hero-block__boutique-link--label {
    font-family: $font_2;
  }
  .product-full__misc-flag,
  .product-brief__misc-flag,
  .product-brief__misc-flag-spacer {
    font-family: $font_2;
  }
  .ee-how-to__step__copy a {
    font-family: $font_2;
  }
  .ee--subhead,
  .ee-landing__filters li,
  .ee-landing__filters__side__wrapper {
    font-family: $font_1;
  }
  .pp__headline--sub-section,
  .pp__headline--sub-section-sub {
    font-family: $font_2;
  }
  .free_standard_shipping_returns {
    font-family: $font_2;
  }
  .terms-heading {
    font-family: $font_2;
  }
  #colorbox.colorbox__quickshop {
    .quickshop {
      font-family: $font_2;
      h3 {
        font-family: $font_1;
      }
    }
    .quickshop__view-full {
      font-family: $font_2;
    }
    .quickshop__sub-header {
      font-family: $font_1;
    }
    .quickshop__attribute {
      h5 {
        font-family: $font_2;
      }
      p {
        font-family: $font_2;
      }
    }
    .quickshop__add-button {
      font-family: $font_2;
    }
    .quickshop__detail-link {
      font-family: $font_2;
    }
    .quickshop__share {
      font-family: $font_2;
    }
    .quickshop__wishlist {
      font-family: $font_2;
    }
    .quickshop__tabs-control .quickshop__tab-control {
      font-family: $font_2;
    }
  }
  /* Search */
  .search-product__header,
  .search-product__sub-header {
    font-family: $font_3;
  }
  .el-search-block {
    input[type='text'].form-text {
      font-family: $font_1;
    }
    .el-search-block__links h5 {
      font-family: $font_2;
    }
    .el-search-block__links a {
      font-family: $font_1;
    }
  }
  .search-page {
    input[type='text'].search-term {
      font-family: $font_1;
    }
    .search-page__headline {
      font-family: $font_1;
    }
  }
  .search-product__misc-flag {
    font-family: $font_2;
  }
  .typeahead-wrapper {
    .product-result__info {
      font-family: $font_3;
    }
    .product-result__name {
      font-family: $font_3;
    }
    .product-result__sub-name {
      font-family: $font_3;
    }
  }
  .typeahead-summary p,
  .typeahead-see-results a {
    font-family: $font_2;
  }
  /* Account */
  #forgot_password,
  .password-sent-page .password-sent-page__content .password-sent-page__email-header {
    font-family: $font_2;
  }
  .sign-in-component {
    .sign-in-component__header {
      font-family: $font_1;
    }
    .sign-in-component__label,
    .sign-in-component__birthday-program label,
    .sign-in-component__sms-signup label {
      font-family: $font_2;
    }
    .sign-in-component__form--registration .sign-in-component__terms,
    .sign-in-component__form--registration .sign-in-component__terms p {
      font-family: $font_2;
    }
  }
  .account-data__controls-link,
  .delete-data-confirm__controls-link,
  .address-book__controls-link {
    font-family: $font_2;
  }
  .account-data-form__label {
    font-family: $font_2;
  }
  .last-purchased__header {
    font-family: $font_2;
  }
  .account-utilities {
    .account-utilities__header {
      font-family: $font_2;
    }
    .account-utilities__link {
      font-family: $font_2;
    }
    .account-utilities__link_livechat {
      font-family: $font_2;
    }
    .account-utilities__account-nav {
      .account-utilities__link.active {
        font-family: $font_2;
      }
    }
  }
  .registration-page {
    .label {
      font-family: $font_2;
      p {
        font-family: $font_2;
      }
    }
    .sms-promotions__copy {
      font-family: $font_2;
      p {
        font-family: $font_2;
      }
    }
  }
  .delete-address-confirm {
    .delete-address-confirm__payment-info-header {
      font-family: $font_2;
    }
    .delete-payment-confirm__payment-info-header {
      font-family: $font_2;
    }
    .delete-address-confirm__controls-link {
      font-family: $font_2;
    }
    .delete-payment-confirm__controls-link {
      font-family: $font_2;
    }
  }
  .delete-payment-confirm {
    .delete-address-confirm__payment-info-header {
      font-family: $font_2;
    }
    .delete-payment-confirm__payment-info-header {
      font-family: $font_2;
    }
    .delete-address-confirm__controls-link {
      font-family: $font_2;
    }
    .delete-payment-confirm__controls-link {
      font-family: $font_2;
    }
  }
  .address-form .address-form__form-label {
    font-family: $font_2;
  }
  #address_form_container .ship_type_container label {
    font-family: $font_2;
  }
  #photo-upload-query div {
    font-family: $font_1;
  }
  /* Samples */
  .choose-samples-and,
  .remove-sample {
    font-family: $font_2;
  }
  .samples-page {
    .samples-panel__title {
      font-family: $font_1;
    }
    .available {
      font-family: $font_3;
    }
  }
  .samples .product-name {
    font-family: $font_3;
  }
  .samples-panel {
    .product-subhead {
      font-family: $font_3;
    }
    .product-size {
      font-family: $font_3;
    }
    .skus {
      label {
        font-family: $font_2;
      }
    }
  }
  .add-samples-message {
    font-family: $font_3;
  }
  /* Customer Service */
  .customer-service .sidebar-page__content {
    ul {
      font: 15px/24px $font_2;
    }
    ol {
      font: 15px/24px $font_2;
    }
    p {
      font-family: $font_2;
      &.customer-service-callout__text {
        font-family: $font_3;
      }
    }
  }
  .cs-quick-info {
    .cs-quick-info__title {
      font-family: $font_2;
    }
  }
  .careers-page {
    .careers-page__landing-link {
      font-family: $font_2;
    }
    .careers-landing {
      .career-landing__profile-link {
        font-family: $font_2;
      }
    }
  }
  .need-help-panel {
    .phone,
    .chat,
    .email {
      h4 {
        font-family: $font_2;
      }
    }
    .need-help-panel__title {
      font-family: $font_1;
    }
  }
  /* Beauty Profile */
  .beauty-feed-tout--products .sku-brief-editorial__shadename {
    font-family: $font_3;
  }
  .beauty-profile__tools-header,
  .beauty-profile__tools-link {
    font-family: $font_2;
  }
  .beauty-questions__label {
    font-family: $font_2;
  }
  .beauty-profile__questions-submit {
    font-family: $font_2;
  }
  .beauty-chat-tray {
    .beauty-chat-tray .beauty-chat-tray__learn-more {
      font-family: $font_2;
    }
    .beauty-chat-tray__title {
      font-family: $font_3;
    }
    .beauty-chat-tray__hours-of-availability,
    .beauty-chat-tray__hours-of-availability p {
      font-family: $font_2;
    }
  }
  .beauty-feed-tout {
    .social-user {
      font-family: $font_2;
    }
    .social-action {
      font-family: $font_2;
    }
  }
  .beauty-consultations {
    font-family: $font_1;
  }
  .formatter-beauty-feed__header > .headline,
  .formatter-beauty-feed__footer > .headline {
    font-family: $font_1;
  }
  .beauty-feed-tout--tool__headline {
    font-family: $font_1;
  }
  .beauty-feed-tout--twitter .social-content {
    font-family: $font_1;
  }
  .beauty-feed-tout--products .sku-brief-editorial__size {
    font-family: $font_3;
  }
  .product_brief__misc-flag {
    font-family: $font_2;
  }
  /* My feed */
  .my-feed-tray {
    .my-feed-tray__anon {
      .my-feed-summary__header,
      .my-feed-summary__copy {
        font-family: $font_3;
      }
    }
    .my-feed-tray__video-header,
    .my-feed-tray__video-title {
      font-family: $font_2;
    }
  }
  .my-feed-summary__welcome-back,
  .my-feed-summary__signout {
    font-family: $font_3;
  }
  /* Special offers */
  .special-offers-tray {
    .offers-email-signup-form,
    .offers-register-form,
    .offers-sign-in-form {
      .headline--offers-tray {
        font-family: $font_1;
      }
    }
    .offers-email-signup-form .offers-email-signup-form__sign-in-wrapper {
      font-family: $font_2;
      a {
        font-family: $font_2;
      }
    }
    .offers-email-signup-form .offers-email-signup-form__message {
      font-family: $font_2;
    }
    .gift-wrap .gift-wrap__content,
    .gift-wrap .special-offer-benefits__content,
    .special-offer-benefits .gift-wrap__content {
      font-family: $font_3;
    }
    .special-offer-benefits {
      .special-offer-benefits__content {
        font-family: $font_1;
        span {
          font-family: $font_2;
        }
      }
    }
    .gift-wrap .gift-wrap__link-wrapper a,
    .gift-wrap .special-offer-benefits__link-wrapper a,
    .special-offer-benefits .gift-wrap__link-wrapper a,
    .special-offer-benefits .special-offer-benefits__link-wrapper a {
      font-family: $font_2;
    }
  }
  /* Botique */
  .boutique-tout {
    .boutique-tout-eyebrow-wrapper .boutique-tout-eyebrow {
      font-family: $font_2;
    }
    .boutique-tout-main-headline-wrapper .boutique-tout-main-headline {
      font-family: $font_1;
    }
    .boutique-tout-copy-block-wrapper .boutique-tout-copy-block > span {
      font-family: $font_2;
    }
    &.boutique-tout-size-small {
      .boutique-tout-eyebrow-wrapper .boutique-tout-eyebrow {
        font-family: $font_2;
      }
      .boutique-tout-main-headline-wrapper .boutique-tout-main-headline {
        font-family: $font_1;
      }
      .boutique-tout-copy-block-wrapper .boutique-tout-copy-block > span {
        font-family: $font_2;
      }
    }
  }
  .boutique-tout-button {
    font-family: $font_2;
  }
  .boutique-custom__link {
    a.button-explore.button-explore-label {
      font-family: $font_2;
    }
  }
  .el_boutique_h2 {
    .el_boutique_body {
      font-family: $font_2;
    }
  }
  .ff-quiz__inner {
    .ff-quiz__headline,
    .ff-quiz__edit-headline,
    .ff-quiz__headline--secondary {
      font-family: $font_1;
    }
    .ff-quiz__headline--tertiary,
    .ff-quiz__nav-menu {
      font-family: $font_2;
    }
    .ff-quiz__button--next {
      font-family: $font_3;
    }
    .ff-quiz__coverage-image,
    .ff-quiz__intensity-image {
      font-family: $font_1;
    }
  }
  .ff-quiz--edit {
    .ff-quiz__inner {
      .ff-quiz__headline--secondary {
        font-family: $font_2;
      }
    }
  }
  .ff-results__regimen-step-product .product_brief__header,
  .ff-results__regimen-step-product .product_brief__sub-header,
  .ff-results__product .sku-brief-editorial__shadename {
    font-family: $font_3;
  }
  .ff-results {
    .ff-results__matches-sub-headline {
      font-family: $font_3;
    }
    .ff-results__regimen-step-headline {
      font-family: $font_2;
    }
    .ff-results__count {
      font-family: $font_1;
    }
    .ff-results__matches-copy,
    .ff-results__matches-copy a,
    .ff-results__matches-copy p {
      font-family: $font_2;
    }
    .ff-results__regimen-step-instructions,
    .ff-results__regimen-step-instructions a {
      font-family: $font_2;
    }
    .ff-results__regimen-step-gutter {
      font-family: $font_1;
    }
  }
  .ff-results__product .sku-brief-editorial .sku-brief-editorial__size {
    font-family: $font_3;
  }
  .ff-results__regimen-step-product .product_brief__price {
    font-family: $font_2;
  }
  /* Responsive carousel */
  .responsive-carousel {
    .slide__caption {
      font-family: $font_3;
    }
    .slide__link {
      font-family: $font_2;
    }
  }
  .responsive-carousel__title {
    font-family: $font_2;
  }
  .r3__container {
    .r3__states .r3__sub-header {
      font-family: $font_2;
    }
    .r3__overlay .r3__overlay-header {
      font-family: $font_1;
    }
  }
  .homepage-subscribe .homepage-subscribe__header {
    font-family: $font_2;
  }
  .sms-signup__terms label {
    font-family: $font_2;
  }
  .country-chooser__selector {
    font-family: $font_2;
  }
  .wishlist-confirm__message {
    font-family: $font_1;
  }
  .estee-edit__header-tags-label {
    font-family: $font_2;
  }
  .formatter-estee-edit__content {
    .basic-textarea-v1 {
      p {
        font: 18px/26px $font_2;
      }
    }
  }
  .ee-first-letter {
    font: 26px/60px $font_1;
  }
  .up-next__label {
    font-family: $font_1;
  }
  div.livechat-transcripts-page dl.transcripts {
    font-family: $font_2;
    dd.transcript div {
      &.transcript-lines h3.transcript-section-header {
        font-family: $font_2;
      }
      &.recommended-skus h3.transcript-section-header {
        font-family: $font_2;
      }
    }
  }
  /* Footer */
  .page-footer {
    font-family: $font_2;
    .footer-header,
    .country_chooser__header,
    .sms-signup__header,
    .email_signup__header {
      font-family: $font_3;
    }
    .language-select {
      .language-select__header {
        font-family: $font_2;
      }
      .language-select__language01,
      .language-select__language02 {
        font-family: $font_3;
      }
    }
  }
  .page-sticky-footer {
    .drawer-formatter__content .special-offer {
      .special-offer__text1,
      .special-offer__text2 {
        font-family: $font_2;
      }
      .special-offer__header {
        font-family: $font_1;
      }
    }
    .page-sticky-footer__left .node-elc-nodeblock .sticky-footer__my-feed-link .item-count {
      font-family: $font_2;
    }
    .page-sticky-footer__right .menu > li .sticky-footer__my-feed-link .item-count {
      font-family: $font_2;
    }
  }
  .footer-phone .footer-phone__link {
    font-family: $font_2;
  }
  /* Store locator */
  &.section-store-locator.device-pc {
    .store-locator {
      .store-locator__title {
        font-family: $font_1;
      }
      .location-info {
        font-family: $font_2;
      }
      .intl-search {
        .intl-search__label {
          font-family: $font_1;
        }
        .intl-search__copy {
          font-family: $font_2;
        }
      }
      .store-results-header {
        font-family: $font_3;
      }
      .door-row {
        .door-row__name {
          font-family: $font_3;
        }
        .door-row__closest {
          font-family: $font_2;
        }
      }
      .local-search__option {
        font-family: $font_2;
        &.active {
          font-family: $font_2;
        }
      }
      .directions-form__results {
        font-family: $font_2;
      }
      .store-locator__nav .store-locator__nav-item {
        font-family: $font_2;
        &.active {
          font-family: $font_2;
        }
      }
      .doors-results__pager .doors-results__pager-view-all {
        font-family: $font_2;
      }
    }
    .store-locator-location-info .location-info__door-name {
      font-family: $font_3;
    }
    .find-a-store-page .promo-unit--threecol .promo-unit__headline {
      font-family: $font_3;
    }
  }
  .store-locator-block {
    .store-locator-block__results {
      .store-locator-block__result-doorname {
        font-family: $font_3;
      }
      .store-locator-block__result-address {
        font-family: $font_3;
      }
      .store-locator-block__results-header {
        font-family: $font_2;
      }
      .store-locator-block__link a {
        font-family: $font_2;
      }
    }
    .store-locator-block__errors {
      font-family: $font_2;
    }
    .store-locator-block__header {
      font-family: $font_2;
    }
    .store-locator-block__loading-message {
      font-family: $font_2;
    }
  }
  &.brand-renutriv {
    .page-main {
      font-family: $font_11;
      p {
        font-family: $font_11;
        &.copy {
          font-family: $font_11;
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: $font_8;
      }
    }
    .headline--primary,
    .headline--large,
    .headline--secondary,
    .headline--medium,
    .headline--page,
    .headline--products,
    blockquote,
    .pull-quote {
      font-family: $font_8;
    }
    .text-primary-headlines,
    .text-medium-headlines {
      font-family: $font_8;
    }
    h3.discover-more__header,
    .discover-more__header,
    .discover_more__product-header {
      font-family: $font_8;
    }
    .discover_more__product-sub-header,
    .discover_more__product-shopnow {
      font-family: $font_11;
    }
    select,
    a.selectBox,
    a.selectbox,
    .selectBox-options li a {
      font-family: $font_11;
    }
    .spp-product__details {
      .spp-product__details-header {
        font-family: $font_8;
      }
      .spp-product__details-description {
        font-family: $font_11;
        p {
          font-family: $font_11;
        }
      }
      .free_standard_shipping_returns,
      .spp-product__details-attribute__label {
        font-family: $font_11;
      }
      .spp-product__details-attribute {
        font-family: $font_11;
        p {
          font-family: $font_11;
        }
      }
    }
    .product-full {
      font-family: $font_11;
      .cta {
        font-family: $font_11;
      }
    }
    .product-full__misc-flag,
    .product-full__subtitle,
    .product-full__detail-link,
    .product-full__share,
    .product-full__wishlist {
      font-family: $font_11;
    }
    .product-full__attribute {
      h5 {
        font-family: $font_11;
      }
      p {
        font-family: $font_11;
      }
    }
    .product_brief__header,
    .product-full h3.product-full__title {
      font-family: $font_8;
    }
    .mpp__header {
      font-family: $font_8;
    }
    .product_brief__misc-flag,
    .product_brief__sub-header,
    .product_brief__price {
      font-family: $font_11;
    }
    .product_brief {
      .button--secondary {
        font-family: $font_11;
      }
    }
    .product_brief__buttons--non-shaded,
    .product_brief__buttons--shaded {
      .product_brief__button--shop-now {
        font-family: $font_11;
      }
    }
    .spp-product__attribute {
      p,
      h5 {
        font-family: $font_11;
      }
    }
    #colorbox.colorbox__quickshop {
      .quickshop {
        font-family: $font_11;
        .quickshop__view-full {
          font-family: $font_11;
        }
        .quickshop__header,
        .quickshop__sub-header {
          font-family: $font_8;
        }
      }
      .quickshop__attribute {
        h5 {
          font-family: $font_11;
        }
        p {
          font-family: $font_11;
        }
      }
      .quickshop__add-button,
      .quickshop__detail-link,
      .quickshop__share,
      .quickshop__wishlist {
        font-family: $font_11;
      }
      .quickshop__tabs-control {
        .quickshop__tab-control {
          font-family: $font_11;
        }
      }
    }
    .hero-tout-fullwidth {
      .text-promo {
        font-family: $font_11;
      }
      .cta-button-wrapper .cta {
        font-family: $font_11;
      }
    }
    .chat-parallax__headline {
      font-family: $font_8;
    }
    .sms-signup__terms {
      label {
        font-family: $font_11;
      }
    }
    .hero-block--renutriv {
      .hero-block__headline {
        .header__headline {
          font-family: $font_8;
        }
      }
    }
  }
  .hero-block--renutriv {
    .hero-block__promo .text-promo__content {
      font-family: $font_11;
    }
  }
  /* BV Implementation */
  .BV,
  .BVSubmissionTimeout {
    font-family: $font_2;
  }
  .BVRRTitle,
  .BVMEPageHeader,
  .BVMESectionHeader {
    font-family: $font_1;
  }
  button.BVButton,
  .BVFieldPreview .BVButton,
  .BVFieldSubmit .BVButton {
    font-family: $font_2;
  }
  .BVSelectField div.selector select {
    font-family: $font_2;
  }
  .uploader {
    font-family: $font_2;
    input[type='file'] {
      font-family: $font_2;
    }
  }
  .BVRRRootElement {
    font-family: $font_2;
    input,
    select,
    textarea {
      font-family: $font_2;
    }
  }
  .BVRRContainer {
    div.selector {
      select {
        font-family: $font_2;
      }
    }
  }
  .BVDI_FV {
    .BVDI_FVVote {
      a {
        font-family: $font_2;
        &:hover,
        &.focus,
        &:active {
          font-family: $font_2;
        }
      }
    }
  }
  .BVRRPhotoPopup,
  .BVRRVideoPopup {
    font-family: $font_2;
  }

  /***** Mobile Specific Styles *****/

  &.device-mobile {
    .sign-in-component {
      .sign-in-component__confirm--registration .sign-in-component__header {
        font-family: $font_1;
      }
    }
    .page-sticky-footer .page-sticky-footer__right .menu a {
      font-family: $font_2;
    }
    .footer-forms-location-sms-email-signup-elc-nodeblock {
      .footer-forms__header {
        font-family: $font_2;
      }
    }
    /* Page Navigation */
    .index-navigation,
    .page-navigation {
      .depth-1 h3 {
        font-family: $font_3;
        @include iPhone_en;
      }
    }
    .page-utilities {
      &__help-text,
      &__account-text,
      &__signin-text,
      &__cart-text {
        font-family: $font_3;
        @include iPhone_en;
      }
    }
    .page-navigation {
      .level-1,
      .level-2,
      .level-3,
      &__menu_text {
        font-family: $font_3;
        @include iPhone_en;
      }
    }
    .utility-item-text {
      font-family: $font_3;
      @include iPhone_en;
    }
    .footer-main-menu {
      .menu {
        a {
          font-family: $font_3;
          @include iPhone_en;
        }
      }
    }
    .ee-landing__filters {
      font-family: $font_3;
      @include iPhone_en;
    }
    .account-mobile-landing-page {
      .account-landing-menu {
        .account-landing-menu__link {
          font-family: $font_3;
          @include iPhone_en;
        }
        .brand-aerin .page-main .account-landing-menu__link {
          font-family: $font_2;
        }
      }
      .account-landing-profile {
        .account-landing-profile__header {
          font-family: $font_2;
        }
        .account-landing-profile__content .account-landing-profile__details {
          .account-landing-profile__details-header {
            font-family: $font_2;
          }
        }
      }
    }
    .product-brief__button-quickshop--close {
      font-family: $font_2;
    }
    .quickshop-inline {
      .quickshop-inline__attribute-header,
      .quickshop-inline__wishlist,
      .quickshop-inline__details {
        font-family: $font_2;
      }
      .quickshop-inline__attribute-text {
        font-family: $font_3;
      }
    }
    .spp-product__details {
      font-family: $font_2;
      h5 {
        font-family: $font_2;
      }
      li {
        font-family: $font_2;
      }
    }
    .text-product-names-main,
    .text-product-names-sub,
    .spp-product__header,
    .spp-product__sub-header,
    .spp-howtouse__header {
      font-family: $font_1;
    }
    .product-brief__sub-header {
      font-family: $font_3;
      @include iPhone_en;
    }
    .spp-discover-more-section-mobile {
      h4.spp-product__section-header {
        font-family: $font_2;
      }
      .discover_more__product {
        .discover_more {
          &__product-header,
          &__product-sub-header {
            font-family: $font_3;
            @include iPhone_en;
          }
        }
      }
    }
    &.brand-renutriv {
      .spp-discover-more-section-mobile {
        h4.spp-product__section-header {
          font-family: $font_8;
        }
        .discover_more__product {
          .discover_more__product-header,
          .discover_more__product-sub-header {
            font-family: $font_8;
          }
        }
      }
      .quickshop-inline__add-button,
      .free_standard_shipping_returns {
        font-family: $font_11;
      }
      .product-brief {
        &__button-quickshop {
          font-family: $font_11;
        }
      }
    }
    .special-offer-row-mobile {
      &.special-offer-theme-regular {
        .special-offer-headline {
          font-family: $font_1;
        }
        .special-offer-eyebrow {
          font-family: $font_2;
        }
      }
    }
    .promotions {
      .promo-unit__headline {
        font-family: $font_1;
      }
    }
  }
  /* End Mobile Specific Styles */
  .wishlist-page {
    .prod_name,
    .wishlist_prod_name,
    .wishlist_prod_subname {
      font-family: $font_3;
      @include iPhone_en;
    }
    .prod_subname {
      font-family: $font_2;
    }
  }
} /* End body tag */
/* Boutiques renutriv-alchemy page styles */
[lang='zh-e-HK'] {
  body {
    #re-nutriv-alchemy_container {
      font-family: $font_6;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: $font_4;
      }
      h3 {
        font-family: $font_5;
      }
      .mpp__product-grid {
        .product_brief {
          &__header {
            font-family: $font_4;
          }
          .button--secondary {
            font-family: $font_6;
          }
        }
      }
      h3.product-brief__header,
      .product_description h3 {
        font-family: $font_4;
      }
      .re-nutriv-alchemy_discover span,
      p {
        font-family: $font_9;
      }
      li .shop_now_container a {
        font-family: $font_6;
      }
      .product-brief__button-quickshop {
        font-family: $font_7;
        &--close {
          font-family: $font_7;
        }
      }
      .facechart-products {
        li.el-product {
          h3 {
            font-family: $font_4;
          }
          .el-product_price,
          .el-product_alt_description-text {
            font-family: $font_7;
          }
        }
      }
      .video_container {
        .tile_text_container {
          li.active {
            p.callout {
              font-family: $font_4;
            }
            p.eyebrow {
              font-family: $font_6;
            }
          }
        }
      }
      #re-nutriv-alchemy_section-7 {
        .re-nutriv-alchemy_text.first h3 {
          font-family: $font_9;
        }
      }
    }
  }
  .special-offer-row-wrapper {
    &.special-offer-theme-regular {
      .special-offer-headline {
        font-family: $font_1;
      }
    }
    .special-offer-text-wrapper {
      .special-offer-eyebrow {
        font-family: $font_2;
      }
    }
  }
}
/* End style boutiques renutriv-alchemy */
#supreme-power_container.boutique {
  font-family: $font_2;
  .mpp-carousel {
    .carousel li {
      .el-product_alt_description-text {
        font-family: $font_2;
      }
      .el-product_rgn-subheading {
        font-family: $font_3;
      }
      .el-product_category {
        font-family: $font_3;
      }
    }
  }
}
/* OAB Styles */
[lang='zh-e-HK'] {
  body {
    .appt-book {
      font-family: $font_2;
      h2,
      h3,
      h4 {
        font-family: $font_1;
      }
    }
  }
}
