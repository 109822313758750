/***** For iPhone Specific Style *****/

@mixin iPhone_font_hk {
  font-weight: normal;
}

@mixin iPhone_hk {
  @media #{$iphone5-portrait},
    #{$iphone6-portrait},
    #{$iphone6-landscape},
    #{$iphone6-up},
    #{$iphone6plus-portrait},
    #{$iphone6plus-landscape} {
    @include iPhone_font_hk;
  }
}

/***** End iPhone Specific Style *****/

.device-mobile {
  #colorbox {
    div#cboxWrapper,
    div#cboxContent {
      width: 100% !important;
    }
    #cboxClose {
      #{$rdirection}: 5px;
      top: 5px;
      &:hover {
        #{$rdirection}: 5px;
        top: 5px;
      }
    }
  }
  .spp-discover-more-section-mobile,
  .special-offer-row-mobile.top-border-yes {
    margin-bottom: 0;
  }
}

ul {
  &.error_messages {
    @include swap_direction(margin, 0em 1em 0.8em 1.5em);
  }
}

.pg_wrapper {
  &.viewcart-empty {
    .single-message {
      color: #9eafff;
    }
  }
  .messages {
    color: $color-red;
  }
  .profile-info__item {
    .mobile_code {
      display: inline-block;
      float: left;
      margin-right: 20px;
    }
    .mobile_number {
      display: inline-block;
      float: left;
    }
  }
}

.mpp {
  .quickshop-inline__phone-button {
    display: none;
  }
}

[lang='en-e-HK'] {
  .product_brief {
    &__sub-line {
      display: none;
    }
  }
}

[lang='zh-e-HK'] {
  .product_brief {
    &__sub-line {
      font-size: 25px;
    }
  }
  .product-brief {
    &__sub-header {
      display: none;
    }
  }
  .spp-product {
    &__sub-header {
      display: none;
    }
    &__attribute {
      h5,
      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  .device-mobile {
    font-size: 16px;
    letter-spacing: 0.15em;
    line-height: 24px;
    .footer-main-menu {
      .menu {
        a {
          font-size: 15px;
          letter-spacing: 0.15em;
          font-weight: normal;
        }
      }
    }
    .page-navigation {
      .level-1,
      .depth-1 h3,
      .level-2,
      .level-2 h3 {
        font-weight: normal;
        font-size: 18px;
        letter-spacing: 0.1em;
      }
    }
    .page-footer {
      .language-select {
        .language-select__languages a {
          font-size: $base-font-size;
          letter-spacing: 0.15em;
          font-weight: normal;
        }
      }
      .footer-forms__header,
      .footer-legal,
      .country_chooser__header {
        font-size: 15px;
        letter-spacing: 0.15em;
        font-weight: normal;
      }
      .footer-legal {
        .field-mobile-menu {
          .menu li:nth-child(3n + 3) {
            font-size: 12px;
            letter-spacing: 0.6px;
          }
        }
      }
    }
    &.brand-renutriv {
      .product-brief__sub-header,
      .product-brief__header {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.1em;
        margin-bottom: 7px;
      }
      .spp-product__sub-header {
        font-size: 25px;
        letter-spacing: 0.02em;
        line-height: 45px;
      }
      .spp-product {
        &__attribute {
          h5,
          p {
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.15em;
          }
        }
        &__description {
          .spp-product__header {
            font-size: 45px;
          }
        }
        &__detail-link {
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.1em;
        }
        .free_standard_shipping_returns {
          font-size: 12px !important;
          letter-spacing: 0.15em;
          line-height: 15px;
          font-weight: normal;
        }
      }
      .spp-discover-more-section-mobile {
        h4.spp-product__section-header {
          font-size: 24px;
          letter-spacing: 0.15em;
          font-weight: normal;
          color: $color-white;
        }
        .discover_more__product {
          .discover_more__product-header,
          .discover_more__product-sub-header {
            color: $color-white;
          }
        }
      }
      .spp-product__additional-section {
        .spp-howtouse {
          .spp-howtouse__section1 {
            .spp-howtouse__header.headline--secondary {
              span {
                color: $color-white;
              }
            }
          }
        }
      }
      .mpp {
        h2.mpp__header {
          font-size: 20px;
          line-height: 35px;
          letter-spacing: 0.1em;
        }
      }
    }
    .spp {
      .spp-product__personal,
      .order_arrival #order_arrival_info {
        a {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .spp-discover-more-section-mobile {
        .discover_more {
          &__product {
            .discover_more {
              &__product-header {
                font-size: 21px;
                line-height: 26px;
                letter-spacing: 0em;
              }
              &__product-sub-header {
                font-size: 15px;
                letter-spacing: 0.1em;
                margin: 7px 0;
              }
            }
          }
        }
      }
    }
    .spp-discover-more-section-mobile {
      h4 {
        &.spp-product__section-header {
          font-size: 24px;
          letter-spacing: 0.15em;
          font-weight: normal;
          color: $color-black;
        }
      }
      .discover_more__product {
        .discover_more__product-header {
          font-weight: normal;
        }
        .discover_more__product-sub-header {
          letter-spacing: 0.2em;
          margin: 7px 0;
        }
      }
    }
    .free_standard_shipping_returns {
      font-size: 14px !important;
      line-height: 18px;
    }
    .mpp {
      .mpp__product-grid {
        .product-brief {
          .product_brief {
            &__sub-line {
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.1em;
              margin: 7px 0;
            }
          }
          .product-brief {
            &__price.down_price {
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.1em;
              font-weight: bold;
            }
          }
          &__quickshop-container {
            .quickshop-inline__attribute-header {
              font-weight: normal;
            }
          }
        }
      }
      .mpp__header {
        line-height: 40px;
        letter-spacing: -0.05em;
      }
    }
    .foundation-finder-page {
      .ff-quiz__inner {
        .ff-quiz {
          &__intensity-image,
          &__coverage-image {
            font-size: 72px;
          }
          &__headline {
            font-size: 40px;
            &--tertiary {
              font-size: 14px;
            }
          }
          &__nav-label {
            font-size: 14px;
          }
        }
      }
      .spp_howtouse__header {
        line-height: 70px;
      }
    }
    .product-brief__sub-header {
      @include iPhone_hk;
    }
  }
  .main-content {
    @media #{$small-up} {
      #re-nutriv-alchemy {
        &_section-1 {
          .subhead {
            strong {
              width: 40%;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .page-not-found__header {
    font-size: 24px;
    line-height: 36px;
  }
  .special-offers-page {
    .special-offer-header {
      span {
        font-size: 16px;
        font-weight: normal;
      }
      h1.special-offer-headline {
        margin-top: 18px;
        font-size: 40px;
      }
    }
    .special-offer-button-wrapper {
      margin-top: 30px;
    }
    .promotions {
      .promo-unit__subheader {
        font-weight: normal;
        font-size: 16px;
        padding-top: 12px;
      }
      .promo-unit.is-alternate {
        .promo-unit__subheader {
          font-size: 24px;
        }
      }
    }
    .special-offer-row-mobile {
      .special-offer-content {
        margin-top: 22px;
      }
    }
  }
}

.bisMobileIframe {
  height: 350px;
}

#waitlist {
  input[type='email'] {
    padding: 5px 10px;
    width: 100%;
  }
  input[type='submit'] {
    float: right;
    margin-top: 15px;
    padding: 7px 14px;
    border: 0;
  }
}

.elc-user-state {
  &-logged-in,
  &-anonymous {
    .waitlist_thankyou_message {
      margin: 30px 30px 0 0;
    }
  }
  &-anonymous {
    .page-navigation {
      .menu-reference--account {
        display: none;
      }
    }
  }
  &-logged-in {
    .page-navigation {
      .menu-reference--signin {
        display: none;
      }
    }
  }
}

.page-not-found {
  width: auto;
}

.sign-in-component {
  &.registration-confirmation {
    .sign-in-component {
      &__sms-opt-in {
        display: none;
      }
    }
  }
}

.contact-us-page {
  .contact-form-confirm {
    h3 {
      font-size: 30px;
      font-weight: bold;
    }
  }
}

#address_book {
  .address_form_container {
    .form-item {
      .mobile_code {
        width: auto;
        display: inline-block;
        float: left;
        margin-right: 20px;
      }
      .mobile_number_label {
        display: block;
      }
      .mobile_number {
        display: inline-block;
        width: 197px;
      }
    }
  }
}

#index {
  #shipping-panel {
    .address-form {
      .form-item {
        .area-code {
          display: inline-block;
          float: left;
          label {
            display: block;
          }
        }
        &.phone-1 {
          width: 100%;
        }
        .mobile-number {
          display: inline-block;
        }
      }
    }
  }
}

.page-wrapper {
  .customer-service {
    .sidebar-page__content {
      .sidebar-page__content-title {
        font-size: 40px;
        padding-top: 40px;
      }
      .address-form__address-fields {
        .form-item {
          padding-left: 0;
        }
      }
      .sub-header {
        font-size: 18px;
      }
    }
  }
  .search-page__help {
    .search-page__headline {
      font-size: 30px;
    }
  }
  .main-content {
    .homepage-bricks {
      margin-top: 25px;
      .homepage-product-mobile {
        &.brick {
          margin-top: 0px;
        }
        &__content {
          margin-top: 20px;
        }
        &__promo--c {
          margin-bottom: 34px;
          .text-promo {
            margin: 34px 0px 0px;
            padding: 0;
            .under-cta {
              margin-top: 34px;
              position: inherit;
            }
          }
        }
        .tout-cta-button-wrapper--mobile {
          a {
            margin-bottom: 25px;
          }
        }
      }
    }
    .spp-product {
      &__section {
        .discover_more {
          &__product {
            &-header {
              font-weight: normal;
            }
          }
        }
      }
    }
    .promotions {
      &.formatter-123 {
        p {
          padding: 0px 10px;
          margin: 0;
        }
      }
    }
    .double-wear-page {
      padding-top: 25px;
    }
  }
}

.section-corp-info {
  .field-content {
    h2 {
      font-size: 40px;
    }
  }
}

.section-store-locator {
  &.device-mobile {
    .store-locator {
      padding-top: 40px;
      .local-search-form__banner {
        margin-top: 15px;
        img {
          width: 330px;
          height: 291px;
          text-align: center;
        }
      }
      .doors-results__inner {
        .door-row__booking {
          .store-locator_book-appointment {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
      &__nav {
        .store-locator {
          &__nav-item {
            &.first {
              &.active {
                width: 100%;
              }
            }
            &.last {
              display: none;
            }
          }
        }
      }
    }
  }
}

.ff-results {
  .ff-results__regimen-steps {
    display: none;
  }
}

.spp-product__how-to-use {
  padding-bottom: 20px;
}

.foundation-custom-mpp-page,
.double-wear-page {
  .Cushion.Stick {
    padding-top: 32px;
  }
  .hero-tout-mobile img {
    margin-top: 5px;
  }
}

.landing-best-sellers-page {
  .hero-tout-mobile img {
    margin-top: 30px;
  }
}

.pure-color-envy-collection-page {
  .field-mobile-template {
    .mpp h2.mpp__header {
      margin-top: 90px;
    }
  }
}

.mpp__bestfor {
  .text-promo {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15em;
  }
  .mpp__bestfor--headline {
    font-size: 21px;
    line-height: 27px;
    letter-spacing: 0.1em;
  }
}

.special-offers-page {
  .special-offer-row-mobile.top-border-yes {
    hr.top-border {
      border: none;
    }
  }
}

#beauty_profile {
  .beauty-questions {
    &__msgs {
      margin: 20px 0 20px 0;
      text-align: center;
      display: block;
    }
    &__menu_half {
      min-width: 35%;
    }
  }
}

#registration {
  .profile-info__item {
    select.field {
      min-width: 35%;
    }
  }
  .registration-page__birthday {
    .selectbox {
      width: 100%;
    }
    .optional-info__item {
      .selectbox {
        width: 35%;
      }
    }
  }
  .birthday {
    .form-item {
      min-width: 35%;
    }
  }
}

#wp_samples {
  .has-mobile-checkout-nav {
    .sku {
      width: 100%;
    }
  }
}

.samples {
  .price.checkout_gwp {
    display: none;
  }
}
/***** Boutique page style - Skincare Supreme *****/
#supreme-power_container {
  #supreme-power_section-3 {
    .header-container video {
      opacity: 0;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-black;
    }
    .close_btn {
      position: absolute;
      top: 2%;
      right: 2%;
      width: 36px;
      height: 36px;
      margin: 0;
      z-index: 1;
    }
    .close_btn img {
      display: block;
    }
  }
  #supreme-power_section-1 {
    .text-container h2 {
      font-size: 12vw;
      margin: 60px 0px;
    }
  }
}

.lp_survey_area {
  .lp_question_wrapper {
    .lp_radiobutton_wrapper {
      label {
        &::after,
        &::before {
          background: none;
          box-shadow: none;
          border: none;
        }
      }
    }
  }
}

.spp__reviews_head {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-rd-main-header {
        .pr-rd-review-total {
          clear: both;
        }
      }
    }
  }
}