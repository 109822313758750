/* Gnav Alignment */
#cboxLoadedContent {
  .social-login.gnav {
    margin: 0 25px;
    .social-login__container {
      text-align: center;
      .social-login__divider {
        width: 100%;
      }
      .social-login__email-opt-in {
        text-align: $ldirection;
        margin-top: 20px;
        label {
          text-transform: none;
          font-size: 12px;
        }
      }
      .social-login__terms {
        text-align: $ldirection;
        .social-terms {
          font-size: 12px;
        }
      }
    }
  }
  .registration-confirmation {
    .social-login {
      display: none;
    }
  }
}
/* Account Signin Page */
.sign-in-page {
  .social-login {
    margin: 0 25px;
    @media #{$medium-up} {
      margin: 0;
    }
    &__divider {
      width: 100%;
    }
    &__email-opt-in {
      text-align: $ldirection;
      margin-top: 20px;
      .social-login__opt-in-label {
        text-transform: inherit;
        font-size: 12px;
        font-family: $font_2;
        letter-spacing: 1px;
      }
    }
    &__terms {
      text-align: $ldirection;
      padding: 0;
      p {
        font-size: 12px;
      }
    }
  }
}
/* Account Profile Page */
#registration {
  .profile-page__content {
    .social-info {
      border-top: 1px solid $color-light-gray;
      padding: 20px;
      margin-#{$ldirection}: 0;
      @media #{$medium-up} {
        padding-#{$ldirection}: 0;
        margin-top: 30px;
      }
      &__header {
        font-size: 30px;
      }
      &__content p {
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
/* Account Profile Page Sidebar */
.account-utilities__account-details,
.account-mobile-landing-page {
  .account-profile__connected-accounts {
    font-size: 22px;
    padding: 15px 20px;
    text-transform: capitalize;
    border-top: 1px solid $color-light-gray;
    font-weight: 700;
    font-family: $font_1;
    @media #{$medium-up} {
      font-size: 12px;
      text-transform: uppercase;
      font-family: $font_2;
      font-weight: normal;
      padding: 0;
      border-top: none;
    }
  }
}
/* Checkout Index Page */
.checkout--single-page {
  .social-login {
    display: inline-block;
    &__divider {
      width: 100%;
    }
    &__email-opt-in {
      margin-top: 20px;
      text-align: $ldirection;
      .social-login__opt-in-label {
        text-transform: inherit;
        font-size: 15px;
      }
    }
    &__terms {
      padding: 0;
      text-align: $ldirection;
    }
  }
}
/* Order Confirmation Page */
#confirm {
  .checkout-confirmation-page {
    .social-login {
      padding: 18px 24px;
      @media #{$medium-up} {
        padding: 0;
      }
      &__title {
        font-size: 21px;
        font-family: $font_1;
        @media #{$medium-up} {
          font-family: $font_3;
          font-size: 24px;
        }
      }
      &__email-opt-in {
        margin-top: 20px;
        .social-login__opt-in-label {
          text-transform: initial;
          font-size: 14px;
        }
      }
      &__container {
        border-top: 1px solid $color-light-gray;
      }
      &__terms {
        display: block;
      }
    }
  }
}
/* appointment booking page */
.appointments-container {
  .social-login {
    &__divider {
      width: 100%;
    }
    &__email-opt-in {
      padding-top: 12px;
      label {
        padding-#{$ldirection}: 27px;
        text-align: $ldirection;
      }
    }
    &__terms {
      text-align: $ldirection;
      padding: 0;
    }
  }
}

.appt-book p {
  font-size: 13px;
  line-height: 2em;
}
