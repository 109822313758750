.trust_mark_footer {
  border-top: 1px solid #a4a4ab;
  padding-top: 10px;
  padding-bottom: 10px;
  .trust_mark_footer__link {
    width: 70px;
    height: 77px;
    background-image: url('/media/images/global/sp_trustmark.png');
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: block;
    margin: 34px auto 34px auto;
  }
}

[lang='en-e-HK'] {
  .product-brief {
    &__sub-line {
      display: none;
    }
  }
}

/********** Regional Specific Styles **********/
[lang='zh-e-HK'] {
  .product-brief {
    &__sub-header {
      display: none;
    }
  }
  .device-mobile {
    .email_popover {
      &__content {
        .welcome-title {
          font-size: 30px;
        }
        &.thanks {
          h3 {
            font-size: 24px;
          }
        }
      }
      .welcome15_popover {
        &__link {
          font-size: 14px;
        }
      }
    }
    .promotions {
      .limited-edition-offers-mobile {
        .special-offer-eyebrow {
          display: block;
          margin-bottom: 25px;
        }
      }
    }
    .how-to-use-best-selling-regimen-elc-nodeblock {
      .spp-product {
        &__additional-section {
          .spp-howtouse__section2 {
            .spp-howtouse__header {
              &.headline--secondary {
                font-family: $optimaregular;
                font-size: 21px;
              }
            }
          }
        }
      }
    }
    .spp-product {
      &__additional-section {
        .spp-product__details-attribute {
          h5 {
            font-size: 14px;
          }
        }
      }
    }
  }
  .advanced-night-repair-page {
    .mpp {
      .mpp__header {
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.02em;
        margin-top: 62px;
        padding-top: 65px;
      }
    }
    .hero-block__headline {
      top: 0 !important;
    }
    .hero-tout-mobile__hero--b {
      top: -20px;
    }
    .header__headline {
      padding-top: 55px;
      line-height: 30px;
      font-size: 30px;
      padding-bottom: 18px;
      span {
        font-size: 30px !important;
      }
    }
    .hero-block__promo-copy {
      padding-top: 0px;
      padding-bottom: 2px;
    }
  }
  .customer-service {
    .sidebar-page {
      &__content {
        strong {
          font-size: 24px;
        }
      }
    }
  }
  .panel--offer-code__title {
    font-size: 30px;
  }
  .pp__headline {
    font-size: 24px;
    &--sub {
      font-size: 24px;
    }
  }
}

.email_popover {
  &_title {
    img:first-child {
      display: none;
    }
  }
}

.device-mobile {
  #colorbox.welcome15-overlay {
    #cboxClose {
      top: 55px;
      &:hover {
        top: 55px;
      }
    }
  }
  .Beauty {
    .hero-tout-mobile {
      &__content {
        &--b:first-child {
          margin-top: 90px;
        }
      }
    }
  }
  .spp {
    padding-bottom: 0px;
  }
  .landing-best-sellers-page {
    .content {
      .field-mobile-template {
        .mpp {
          h2.mpp__header {
            margin-top: 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .customer-service--landing {
    .cs-quick-info {
      text-align: left;
      &__block {
        width: auto;
      }
    }
  }
  .foundation-custom-mpp-page {
    .hero-tout-mobile__content--b {
      min-height: 400px;
      margin-bottom: 10px;
      .tout-cta-button-wrapper--mobile {
        position: absolute;
        bottom: 0;
      }
    }
    .hero-block__promo {
      position: inherit;
      .text-promo__subcopy {
        position: absolute;
        bottom: 50px;
        width: 100%;
        left: 0;
      }
    }
    .text-promo__subhead {
      margin-top: 10px;
    }
  }
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  background-image: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_MOBILE.jpg');
}

.wp-overlay-content {
  padding-top: 35px;
}

.wp {
  li {
    &.sku {
      &.button {
        width: 100%;
        padding-right: 2px;
        padding-left: 2px;
      }
    }
  }
}

.registration__email-list-text {
  &.label {
    &.error {
      a {
        color: $color-red;
      }
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .appt_hk_phone_code {
          width: 26%;
          border: solid 1px $color-navy;
          color: $color-navy;
          height: 39px;
          margin: 0 0 1px 0px;
        }
        input {
          &.appt-book-hk-mobile {
            width: 72%;
            height: 39px;
          }
        }
      }
    }
  }
}

.tabbed-products-block {
  .grid--mpp__carousel {
    .grid--mpp__items {
      .grid--mpp__item {
        .product-brief {
          &__sub-line {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.product_brief {
  &__sub-line {
    font-size: 16px;
  }
}

[lang='zh-HK'] {
  .device-mobile {
    .product_brief,
    .product-brief,
    .spp-product {
      &__sub-header {
        display: none;
      }
    }
  }
}

[lang='en-HK'] {
  .device-mobile {
    .product_brief,
    .product-brief,
    .spp-product {
      &__sub-line {
        display: none;
      }
    }
  }
}

.adpl {
  select,
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'] {
    & + label {
      pointer-events: none;
    }
  }
}


.terms_conditions  {
  h3 {
    font-size: 13px;
    letter-spacing: 0.05em;
    font-weight: 700;
  }
  h4 {
    letter-spacing: 1px;
    font-size: 13px;
  }
  li {
    list-style-type: disc;
    @include swap_direction(margin, 0 0 0 2.2em);
  }
  span {
    color: $color-red;
  }
}

.spp-product {
  &__image-container {
    .product-foundation-vto__btn {
      #{$ldirection}: 50px;
      bottom: 100px;
    }
  }
}
