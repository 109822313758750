.loyalty_mrkt_ldng {
  max-width: 1366px;
  margin: 0 auto;
  .loyalty_join_signin {
    margin: 35px 0 35px 65px;
    .form-submit {
      width: 125px;
      display: inline;
      margin-#{$rdirection}: 10px;
      &.btn_sign_in {
        border: 1px solid $color-navy;
        background-color: $color-white;
        color: $color-navy;
      }
    }
    .offer-percent {
      color: $color-navy;
      letter-spacing: 0.02em;
      font-size: 30px;
      font-family: $font-bauerbodoni-roman;
    }
    .offer-percent-number {
      margin-top: 0;
      text-transform: lowercase;
      letter-spacing: 0.05em;
    }
    .offer-signin {
      margin-bottom: 0;
      font-size: 12px;
    }
  }
  .btn_sign_in {
    display: block;
  }
  .loyalty_tiers {
    max-width: 1090px;
    margin: 0 auto;
    padding: 0 20px;
    .tier {
      width: 100%;
      float: #{$ldirection};
      color: $color-navy;
      @media #{$large-up} {
        width: 33%;
      }
      .loyalty-catgory-name {
        font-size: 25px;
        text-transform: uppercase;
        border-bottom: 1px solid $color-navy;
        width: 180px;
        padding-bottom: 10px;
        text-align: #{$ldirection};
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.02em;
        @media #{$large-up} {
          font-size: 30px;
          width: 82%;
        }
        &.second-catgory {
          width: 270px;
          @media #{$large-up} {
            width: 92%;
          }
        }
        &.final-catgory {
          width: 165px;
          @media #{$large-up} {
            width: 100%;
          }
        }
      }
      .tier_left {
        width: 100%;
        &.benefits_hover_tier_left_off {
          color: $color-navy;
        }
        .points-range {
          text-align: #{$ldirection};
          display: block;
          width: 100%;
          padding-top: 15px;
          font-size: 12px;
          letter-spacing: 0.15em;
        }
      }
      .tier_right {
        width: 100%;
        @media #{$medium-up} {
          padding: 8px 3px 0;
        }
        .tier_bold {
          font-size: 14px;
          font-weight: bold;
        }
        &.three-tier {
          padding: 0;
        }
        &.benefits_hover_tier_left_off {
          color: $color-navy;
        }
        .loyal-benfit {
          @include swap_direction(padding, 0 25px 0 15px);
        }
        .loyal-catgory-benfits {
          list-style-type: disc;
          list-style-position: outside;
          padding-#{$rdirection}: 15px;
          &:before {
            display: none;
          }
        }
      }
    }
    .sub_head {
      font-size: 15px;
      text-transform: capitalize;
      margin-#{$ldirection}: 50px;
      letter-spacing: 0.05em;
    }
    .loyal-love-head {
      font-style: italic;
      text-transform: uppercase;
      font-size: 40px;
      letter-spacing: 0.02em;
      font-family: $font-bauerbodoni-roman;
      margin-#{$ldirection}: 50px;
    }
  }
  .loyalty_points {
    clear: both;
    background-color: $color-white;
    color: $color-navy;
    .points_earn {
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-size: 40px;
      font-family: $font-bauerbodoni-roman;
      &__decscription {
        font-size: 15px;
        letter-spacing: 0.05em;
      }
    }
    .loyalty_question {
      .loyalty_learn_more {
        color: $color-navy;
      }
    }
  }
  .beauity-header {
    font-style: italic;
    text-transform: uppercase;
    font-size: 40px;
    color: $color-navy;
    margin-top: 25px;
    font-family: $font-bauerbodoni-roman;
    line-height: 1;
  }
  .beauity-content {
    color: $color-navy;
    margin-bottom: 0;
  }
  .elist-banner {
    height: 30vw;
    background-image: url('/media/loyalty/elist-banner.jpg');
    background-size: cover;
    max-width: 1366px;
    max-height: 400px;
    .elist-banner {
      &-header {
        @include swap_direction(padding, 14vw 0 0 4vw);
        font-size: 4.3vw;
        color: $color-white;
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.01em;
        @media #{$xxlarge-up} {
          @include swap_direction(padding, 190px 0 0 55px);
          font-size: 60px;
        }
      }
      &-content {
        margin: 10px;
        font-size: 1.32vw;
        color: $color-white;
        font-weight: 500;
        letter-spacing: 0.15em;
        padding-#{$ldirection}: 5vw;
        @media #{$xxlarge-up} {
          font-size: 18px;
          padding-#{$ldirection}: 50px;
        }
      }
    }
  }
  .elist-content {
    margin-#{$ldirection}: 50px;
  }
}

.field-mobile-template {
  .loyalty_market {
    .loyalty_market {
      &__btn__signin {
        border: 1px solid $color-navy;
        background-color: $color-white;
        color: $color-navy;
      }
      &__points {
        @include swap_direction(padding, 18px 5px 28px);
        background-color: $color-white;
        color: $color-navy;
        &__offer {
          text-transform: initial;
          font-size: 15px;
          letter-spacing: 0.05em;
          line-height: 1.6;
          br {
            display: none;
          }
        }
        &__list {
          line-height: 1.6;
        }
        &__link {
          font-size: 15px;
        }
      }
      &__tier__header {
        text-transform: uppercase;
        font-size: 28px;
        letter-spacing: 0.02em;
        font-family: $font-bauerbodoni-roman;
      }
      &__tier__points {
        font-weight: bold;
        font-size: 12px;
      }
      &__tiers__text {
        text-transform: initial;
        font-size: 15px;
        letter-spacing: 0.05em;
        br {
          display: block;
        }
      }
      &__tiers__header {
        letter-spacing: 0.02em;
        font-size: 35px;
        text-transform: uppercase;
        font-style: italic;
        font-family: $font-bauerbodoni-roman;
      }
      &__cta__offer_discount {
        margin: 15px 0;
        font-size: 28px;
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.02em;
        line-height: 1.2;
      }
      &__cta__offer_member {
        font-weight: 100;
        text-transform: lowercase;
        font-size: 15px;
        letter-spacing: 0.05em;
      }
      &__cta__offer {
        font-size: 12px;
        letter-spacing: 0.05em;
        margin: 15px;
      }
      &__header {
        margin: 0 15px;
        font-size: 40px;
        line-height: 1;
        text-transform: uppercase;
        font-style: italic;
        font-family: $font-bauerbodoni-roman;
      }
      &__points__header {
        font-size: 35px;
        line-height: 1.25;
        text-transform: uppercase;
        font-style: italic;
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.02em;
      }
      &__text {
        font-size: 15px;
        line-height: 1.6;
        margin: 15px 0;
        br {
          display: block;
        }
      }
      &__tier__list {
        padding-#{$ldirection}: 15px;
        &__item {
          list-style-position: outside;
          line-height: 1.6;
        }
      }
    }
  }
  .elist-banner {
    height: 64vw;
    background-image: url('/media/loyalty/elist-mobile-banner.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    .elist-banner {
      &-header {
        font-size: 10vw;
        color: $color-white;
        letter-spacing: 0.03em;
        padding-top: 54vw;
        font-family: $font-bauerbodoni-roman;
      }
      &-content {
        letter-spacing: 0.15em;
        margin: 4px;
        font-size: 4vw;
        color: $color-white;
        font-family: $akzidenz;
      }
    }
  }
  .loyalty_multi_image_tout {
    .loyalty_multi_image_tout {
      &_left {
        position: static;
      }
      &_right {
        padding-top: 0;
      }
    }
  }
}

#cboxOverlay {
  &.signin-overlay-loyalty,
  &.loyalty-offer-overlay {
    background: transparent;
    .password-field__rules {
      li {
        color: $color-navy;
        &.pass {
          color: $color-green;
        }
      }
    }
  }
}

.loyalty-offer-overlay {
  #cboxContent {
    padding: 0;
    background: $color-white;
    .loyalty_popover {
      @media #{$medium-up} {
        padding: 30px 80px;
      }
      padding: 30px 0;
      color: $color-navy;
      .loyalty_popover {
        &__input {
          .form-submit {
            border: 1px solid $color-navy;
            color: $color-white;
            background: $color-navy;
          }
          .form-text {
            margin-bottom: 10px;
          }
          .form-text,
          .form-password {
            @include input-placeholder {
              color: $color-medium-gray;
            }
            border: 1px solid $color-gray;
            color: $color-navy;
          }
        }
        &__link,
        &__checkbox-link,
        &__terms,
        &__privacy {
          color: $color-navy;
        }
        &__checkbox-container {
          width: auto;
          margin-top: 15px;
          .loyalty_popover__checkbox {
            &-label {
              padding-#{$ldirection}: 30px;
              &:before {
                background: $color-white;
                border: 1px solid $color-navy;
              }
              &:after {
                background-image: url('/media/loyalty/crossmark.png');
                border: 1px solid $color-navy;
              }
            }
            &-link {
              color: $color-navy;
            }
          }
        }
        &__button {
          .button {
            border: 1px solid $color-navy;
            color: $color-white;
            background: $color-navy;
          }
        }
        &__terms {
          clear: both;
          margin-top: 0;
        }
        &__header {
          font-size: 40px;
          font-style: italic;
          font-family: $font-bauerbodoni-roman;
          text-transform: uppercase;
          letter-spacing: 0.01em;
          line-height: 1;
        }
        &__choose {
          text-transform: initial;
          font-size: 15px;
          letter-spacing: 0.05em;
        }
        &__promo-header {
          font-size: 15px;
          font-family: $akzidenz;
          letter-spacing: 0.05em;
          margin-top: 0;
          line-height: 0.83;
        }
        &__promo {
          text-transform: initial;
          letter-spacing: 0.05em;
          margin: 15px 0;
        }
        &__sub-header {
          font-size: 12px;
          font-family: $akzidenz;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          margin-top: 15px;
          line-height: 1.2;
        }
        &__status {
          letter-spacing: 0.05em;
          margin-bottom: 0;
        }
        &__offer-note {
          margin-top: 10px;
          letter-spacing: 0.05em;
        }
        &__submit-container {
          margin-bottom: 20px;
        }
        &__signup {
          @media #{$medium-up} {
            @include swap_direction(padding, 20px 10px 0 0);
          }
        }
      }
    }
  }
  #cboxClose {
    background-image: url(/media/images/pro_active_popup/close.jpg) !important;
    margin: 20px;
  }
}

/* Checkout page */
.offer-section {
  .offer-code-panel {
    .checkout__panel__title {
      @media #{$medium-up} {
        font-size: 22px;
        padding-bottom: 16px;
        cursor: pointer;
      }
    }
  }
}

.page-wrapper-checkout {
  .checkout__offer-code__wallet {
    visibility: hidden; //hide pre slick carousel
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    @media #{$medium-up} {
      height: 430px;
      margin-bottom: 30px;
    }
    &.offer_applied {
      &.slick-slider {
        .checkout__offer-code__wallet-item {
          &.automatic {
            .checkout__offer-code__wallet-apply {
              display: inline-block;
              border: 1px solid $color-navy;
              background: $color-white;
              color: $color-navy;
            }
          }
        }
      }
    }
    .slick-dots {
      display: none !important;
      @media #{$medium-up} {
        display: block;
        top: 200px;
        bottom: auto;
      }
    }
    .slick-prev {
      width: 16px;
      z-index: 1;
      #{$ldirection}: 0;
      top: 20%;
      @media #{$medium-up} {
        #{$ldirection}: 5px;
      }
    }
    .slick-next {
      background-size: auto auto;
      background-position: -14px -8600px;
      width: 16px;
      #{$rdirection}: 0;
      top: 20%;
      @media #{$medium-up} {
        #{$rdirection}: 5px;
      }
    }
    .checkout__offer-code__wallet {
      &-item {
        text-align: center;
        font-family: $optimaregular;
        font-size: 21px;
        @media #{$medium-up} {
          font-size: 22px;
        }
        &__image-container {
          padding: 0;
          margin-bottom: 16px;
          @media #{$medium-up} {
            margin-bottom: 35px;
          }
        }
        &__inner {
          float: none;
          width: auto;
          padding: 0;
        }
        &__image {
          display: inline-block;
          width: 144px;
          height: 198px;
          background: $color-navy;
          &__title {
            color: $color-white;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 180px;
            font-family: $font-bauerbodoni-roman;
            line-height: 1.1;
            letter-spacing: 0.02em;
          }
        }
        &__header {
          font-family: $optimaregular;
          font-size: 21px;
          @media #{$medium-up} {
            font-size: 22px;
          }
        }
        &__description {
          font-family: $optimaregular;
          font-size: 15px;
          line-height: 1.6;
          @media #{$medium-up} {
            font-size: 16px;
            line-height: 2;
          }
        }
        &__button-container {
          position: relative;
          margin-top: 20px;
          @media #{$medium-up} {
            text-align: center;
          }
        }
        &.is-applied {
          .checkout__offer-code__wallet {
            &-apply {
              display: none;
            }
            &-remove {
              display: block;
            }
            &-selected {
              display: inline-block;
            }
            &-item__promo-applied {
              display: block;
              margin-top: 20px;
              font-size: 12px;
            }
          }
        }
        &__button-loader {
          display: none;
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(/media/images/account/loyalty/ajax-loader.gif) no-repeat center center
            $color-white;
          z-index: 10;
          @media #{$medium-up} {
            background-color: $color-gray-cs-side;
          }
        }
        &__promo-applied {
          display: none;
        }
        &__link-container {
          margin-top: 20px;
        }
        &__link {
          font-size: 12px;
          font-weight: bold;
          line-height: 0.44;
          text-transform: uppercase;
          text-decoration: underline;
        }
        .loyalty__panel__offers__offer-code-wrap {
          @media #{$medium-up} {
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.15em;
            margin: 6px 0;
            font-family: $akzidenz;
            .loyalty__panel__offers__offer-code {
              font-weight: bold;
            }
          }
        }
      }
      &-apply {
        display: inline-block;
        border: 1px solid $color-navy;
        background: $color-white;
        color: $color-navy;
      }
      &-remove {
        margin: 10px;
        display: none;
        border: none;
        height: 0;
        line-height: inherit;
        text-decoration: underline;
      }
      &-selected {
        display: none;
        background: $color-navy;
        color: $color-white;
        pointer-events: none;
      }
      &-apply,
      &-remove,
      &-selected {
        height: 33px;
        line-height: 2.8;
      }
    }
    &-count {
      @include swap_direction(padding-bottom, 15px);
      text-align: center;
    }
  }
  .checkout {
    .checkout__content {
      .registration-panel {
        &__title,
        &__registration-form-container,
        &__privacy-policy,
        .section-email-promotions {
          display: none;
        }
      }
    }
  }
}
.viewcart {
  .checkout__sidebar {
    width: 100%;
    @media #{$large-up} {
      width: 34.5%;
    }
    .tab-content {
      .samples-offer-carousel-panel {
        .panel--offer-code__header {
          &.checkout__panel__header {
            display: block;
            .checkout__panel__title {
              padding-bottom: 16px;
            }
          }
        }
      }
    }
  }
}

.offer-code__form {
  .offer-code {
    &__input {
      @media #{$medium-up} {
        margin-bottom: 0;
        float: #{$ldirection};
        width: 60%;
      }
    }
    &__submit {
      .form-submit {
        @media #{$medium-up} {
          @include swap_direction(margin, 0 0 0 10px);
          width: 36%;
          height: 44px;
        }
        @media #{$large-up} {
          width: 30%;
        }
      }
    }
  }
}

.social-login__terms {
  .social-terms {
    font-style: initial;
    letter-spacing: 0.05em;
  }
}

.checkout {
  &.viewcart-empty {
    .offer-code-panel {
      .tab-content {
        #samples-pane {
          display: none;
        }
      }
    }
  }
  &.viewcart {
    .offer-code-panel {
      .tab-content {
        #samples-pane {
          display: block;
        }
      }
    }
  }
  .accordionPanel {
    i {
      position: inherit;
    }
  }
  .checkout__sidebar {
    .samples-offer-carousel-panel {
      padding: 15px 0;
    }
    .checkout__panel-title,
    .checkout__panel__title {
      padding-top: 13px;
      .title-icon {
        position: absolute;
        bottom: 0;
        top: 0;
        #{$rdirection}: 4px;
        width: 45px;
        height: 45px;
        background: url(/media/loyalty/sprites-icons.png);
        background-position: 0 -3269px;
      }
      &.open {
        .title-icon {
          background-position: 0 -2957px;
        }
      }
    }
  }
  &.checkout__index-content {
    .checkout__sidebar,
    .checkout__panel-title {
      .title-icon {
        background-position: 0 -2957px;
      }
      &.open {
        .title-icon {
          background-position: 0 -3269px;
        }
      }
    }
  }
}

.samples-overlay {
  .samples-offer-carousel-panel {
    display: none;
  }
}

.user-loyalty-state {
  @media #{$medium-up} {
    display: block;
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    text-align: #{$rdirection};
    #{$rdirection}: 20px;
    min-width: 375px;
    @media #{$large-down} {
      @media #{$portrait} {
        top: 38px;
      }
    }
    .user-logged-out,
    .user-logged-in {
      display: none;
      &__link {
        margin-#{$ldirection}: 9px;
        color: $color-navy;
        font-weight: normal;
        text-decoration: none;
        text-transform: capitalize;
        letter-spacing: 1px;
        .js-loyalty-points-value {
          font-weight: bold;
        }
      }
    }
  }
  @media #{$large-up} {
    bottom: 0;
  }
  @media #{$medium-only} {
    #{$rdirection}: 100px;
  }
  @media #{$large-only} {
    #{$rdirection}: 10px;
  }
}

.page-utilities__loyalty {
  .user-greeting {
    margin-#{$ldirection}: 9px;
    color: $color-navy;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
  }
  .sign-out-link {
    margin-#{$ldirection}: 7px;
    color: $color-navy;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
  }

  .user-loyalty-state {
    @media #{$medium-up} {
      z-index: 1;
      top: 35px;
    }
  }
}

.account-mobile-landing-page {
  .account-landing-profile__link {
    height: 45px;
    line-height: 4;
    width: 100%;
  }
}

.page-sticky {
  &-footer__left {
    a {
      font-weight: normal;
    }
  }
}

.loyalty-account-page__about {
  &:before {
    width: auto;
  }
  .loyalty_mrkt_ldng {
    .loyalty_tiers {
      padding: 0;
      .loyalty-catgory-name {
        &.final-catgory {
          @media #{$medium-up} {
            font-size: 24px;
            width: 145px;
          }
          @media #{$large-up} {
            font-size: 22px;
            width: 85%;
          }
        }
        @media #{$medium-up} {
          font-size: 24px;
          width: 160px;
          letter-spacing: 0.05em;
        }
        @media #{$large-up} {
          font-size: 22px;
          width: 85%;
        }
      }
    }
    .elist-banner {
      height: 23vw;
      background-size: 100% 100%;
      background: url('/media/loyalty/about-estee.png');
      background-repeat: no-repeat;
    }
    .beauity-header {
      font-size: 40px;
    }
    .loyalty_points {
      @media #{$medium-up} {
        width: auto;
        padding: 0;
      }
      @media #{$large-up} {
        width: 700px;
        padding: 30px;
      }
    }
  }
}

.device-mobile {
  #colorbox {
    &.loyalty-offer-overlay {
      opacity: 1 !important;
    }
  }
}

.user-login-state {
  @media #{$medium-up} {
    #{$rdirection}: 20px;
    height: 48px;
    line-height: 3;
    min-width: 250px;
  }
  @media #{$large-up} {
    min-width: 300px;
  }
  .user-greeting {
    @media #{$medium-up} {
      display: inline-flex;
      width: 140px;
      justify-content: flex-end;
    }
    &__name {
      margin-#{$ldirection}: 7px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
    }
  }
  .sign-out-link {
    line-height: 3.5;
  }
}

#loyalty__content {
  .is-member {
    .loyalty_tiers {
      a {
        display: block;
      }
    }
  }
}

.checkout__sidebar {
  .tab-switcher {
    @media #{$medium-up} {
      display: block !important;
    }
    .tab-bar {
      display: none;
      @media #{$large-up} {
        display: block;
      }
      li {
        &:last-child {
          @media #{$medium-up} {
            width: 30%;
            float: #{$rdirection};
          }
        }
        &:first-child {
          @media #{$medium-up} {
            width: 33%;
          }
        }
      }
    }
    .tab-content {
      .samples-panel {
        display: none;
        @media #{$large-up} {
          display: block;
        }
      }
    }
  }
}

.confirmation-panel {
  &__container {
    .confirmation-page {
      &__points__earned {
        padding-#{$ldirection}: 0;
        @media #{$medium-up} {
          padding-#{$ldirection}: 20px;
        }
      }
    }
  }
}

.loyalty_market__btn {
  &__join_button {
    width: 110px;
    background: $color-navy;
    line-height: 2;
    color: $color-white;
    padding: 15px;
    font-weight: 700;
  }
}
.checkout--single-page {
  .checkout__content {
    .registration-panel {
      .section-loyalty-promotions {
        display: none;
      }
    }
  }
}
.loyalty-join-panel {
  &__content {
    .loyalty-join {
      &__form {
        background: $color-navy;
        text-align: center;
        padding: 20px;
        color: $color-white;
        a {
          color: $color-periwinkle-blue;
          &.button--inverted {
            border: 1px solid $color-periwinkle-blue;
            height: auto;
            line-height: 2;
            @media #{$medium-up} {
              height: 40px;
              line-height: 3;
            }
          }
        }
      }
      &__offer {
        line-height: 3;
      }
      &__text {
        line-height: 1.5;
        padding-bottom: 17px;
      }
      &__terms {
        padding-top: 15px;
      }
      &__no {
        padding-#{$ldirection}: 15px;
      }
    }
  }
}
#loyalty__content {
  #loyalty__panel__benefits {
    .loyalty__current__tier_1 {
      .loyalty__panel {
        &__benefits__tier--2,
        &__benefits__tier--3 {
          .loyalty__panel {
            &__benefits__description-list {
              opacity: 0.3;
            }
          }
        }
      }
    }
    .loyalty__current__tier_2 {
      .loyalty__panel {
        &__benefits__tier--3 {
          .loyalty__panel {
            &__benefits__description-list {
              opacity: 0.3;
            }
          }
        }
      }
    }
  }
}

.loyalty-point-zero,
.viewcart-empty .checkout__sidebar {
  .tab-switcher {
    .tab-content {
      #samples-pane {
        display: none;
      }
    }
  }
}
