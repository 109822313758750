/* Fonts - EL HK - PC and Mobile  */

$font_1: 'OptimaDisplayLight', 'Hiragino Sans GB', 'Microsoft JhengHei', 'STHeiti', 'SimHei', 'sans-serif', 'Tahoma',
  'arial';
$font_2: 'AkzidenzGrotesk W1G', 'Hiragino Sans GB', 'Microsoft JhengHei', 'STHeiti', 'SimHei', 'sans-serif', 'Tahoma',
  'arial';
$font_3: 'Optima Display Roman', 'OptimaDisplayLight', 'Hiragino Sans GB', 'Microsoft JhengHei', 'STHeiti', 'SimHei',
  'sans-serif', 'Tahoma', 'arial';
$font_8: 'Bauer Bodoni W01 Roman', 'StSong', 'SimSun', 'NSimSun', 'serif';

/* For boutiques/renutriv-alchemy */
$font_4: 'Bauer Bodoni W01 Roman', 'BauerBodoniStd-Roman', 'StSong', 'SimSun', 'NSimSun', 'serif';
$font_5: 'Bauer Bodoni W01 Italic', 'BauerBodoniStd-Italic', 'StSong', 'SimSun', 'NSimSun', 'serif';
$font_6: $roboto-condensed, 'Hiragino Sans GB', 'Microsoft JhengHei', 'STHeiti', 'SimHei',
  'sans-serif', 'Tahoma', 'arial';
$font_7: 'AkzidenzGrotesk W1G', 'Hiragino Sans GB', 'Microsoft JhengHei', 'STHeiti', 'SimHei', 'Helvetica',
  'arial', 'sans-serif';
$font_9: $roboto-condensed, 'StSong', 'SimSun', 'NSimSun', 'serif';
/* End boutiques/renutriv-alchemy */

$font_11: 'helvetica-neue', 'Hiragino Sans GB', 'Microsoft JhengHei', 'STHeiti', 'SimHei', 'sans-serif', 'Tahoma',
  'arial';
$font_12: 'Hiragino Sans GB', 'Microsoft JhengHei', 'STHeiti', 'SimHei', 'sans-serif', 'Tahoma', 'arial';
