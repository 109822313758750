/***** For iPhone Specific Style *****/

@mixin iPhone_font_en {
  font-family: $font_1;
  font-weight: bold;
}

@mixin iPhone_en {
  @media #{$iphone5-portrait},
  #{$iphone6-portrait},
  #{$iphone6-landscape},
  #{$iphone6-up},
  #{$iphone6plus-portrait},
  #{$iphone6plus-landscape} {
    @include iPhone_font_en;
  }
}

/***** End iPhone Specific Style *****/
