@if $password_strengthen {
  .password-field {
    &__info {
      #confirm & {
        @media #{$medium-up} {
          #{$rdirection}: -69%;
          top: -12px;
          width: 70%;
        }
      }
    }
  }
  #registration {
    .profile-info__change-pwd-link {
      padding-top: 10px;
    }
    &.device-mobile {
      #colorbox {
        #cboxClose {
          #{$rdirection}: 15px;
          top: 11px;
        }
        &.password-update-overlay {
          height: 580px !important;
          top: 200px !important;
          position: absolute !important;
          .profile-password-update {
            &__rules {
              width: 323px;
              margin-#{$ldirection}: -10px;
            }
            .password-field {
              &__rules {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}

.page-wrapper-checkout {
  .checkout {
    .messages {
      color: $color-red;
    }
  }
}

.device-mobile {
  .password-field {
    &__rules {
      font-size: 11px;
    }
  }
  .password-field {
    .sign-in-component__show-password-wrapper,
    .form-text {
      float: none;
    }
  }
}

.mobile-code {
  width: 44%;
  margin-bottom: 10px;
}
#signin {
  .password-field__info {
    position: relative;
    #{$rdirection}: 0;
    top: 10px;
    &::before {
      #{$ldirection}: 0;
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }
}
