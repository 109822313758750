#bopis-panel {
  .choose_shipping_option {
    @media #{$medium-up} {
      text-align: center;
      margin: 0 auto;
    }
    .bopis-opt {
      padding-#{$ldirection}: 2px;
      margin-top: 13px;
      text-align: $ldirection;
      width: 100%;
      @media #{$medium-up} {
        width: auto;
        display: inline-block;
      }
      input[type="radio"] {
        &~label {
          &:before,
          &:after {
            border-radius: 0;
          }
        }
      }
    }
  }
}
.bopis-overlay {
  .product-store-check {
    margin: 30px 0;
    @media #{$medium-up} {
      margin: 0 24px;
    }
    &__hdr {
      color: $glux-color-menu-title;
      text-align: center;
      .bopis_hdr,
      .bopis_edit_bag_hdr {
        font-size: 32px;
      }
      &:first-child {
        margin: 25px 35px;
        @media #{$medium-up} {
          margin: 25px 0;
          width: 64%;
          float: $ldirection;
          height: 40px;
        }
      }
      @media #{$medium-up} {
        text-align: $ldirection;
      }
    }
    &__subhdr {
      margin-top: 5px;
      text-align: center;
      @media #{$medium-up} {
        text-align: $ldirection;
      }
    }
    &__controls {
      float: $ldirection;
      margin-top: 15px;
      width: 100%;
      @media #{$medium-up} {
        margin: 25px 0;
        width: 36%;
        float: $rdirection;
      }
      .location-controls {
        &__input {
          width: 100%;
          height: 40px;
        }
        &__city {
          float: $ldirection;
          width: 100%;
          @media #{$medium-up} {
            width: 60%;
          }
        }
        &__distance {
          display: none;
          margin: 10px 0 5px 0;
          width: 100%;
          .instore-input-select {
            width: 100%;
            height: 40px;
          }
          @media #{$medium-up} {
            float: $ldirection;
            margin: 0;
            width: 12%;
          }
        }
        &__btn {
          width: 100%;
          height: 40px;
          background: $color-navy;
          margin: 10px 5px 10px 0;
          color: $color-white;
          font-weight: bold;
          font-size: 12px;
          @media #{$medium-up} {
            width: 35%;
            margin: 0 0 0 10px;
          }
        }
        &__errors--error {
          color: $color-red;
        }
      }
    }
  }
  .product-map {
    width: 100%;
    &__map {
      width: 100%;
      display: block;
      float: $ldirection;
      @media #{$medium-up} {
        width: 64%;
        height: 480px;
      }
      .map {
        width: 100%;
        height: 210px;
        @media #{$medium-up} {
          width: 99%;
          height: 465px;
        }
      }
   }
    &__doors {
      width: 100%;
      float: $ldirection;
      @media #{$medium-up} {
        width: 36%;
        height: 480px;
        display: block;
      }
    }
  }
  .no_results_found {
    padding: 2px;
    @media #{$medium-up} {
      padding: 0 15px;
      float: $ldirection;
      background: $color-white;
    }
    input[type="radio"] {
      &~label {
        &:before,
        &:after {
          border-radius: 0;
        }
      }
    }
    .location-controls__btn {
      background: $color-navy;
      color: $color-white;
      font-size: 12px;
      font-weight: bold;
      padding: 0;
      width: 100%;
      margin-top: 5px;
    }
    #bopis_re-search,
    .location-controls__btn--find-store {
      display: none;
    }
  }
  .doors-avail {
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-darker-gray;
    }
    &::-webkit-scrollbar-track {
      background-color: $color-lighter-gray;
    }
    @media #{$medium-up} {
      height: 465px;
    }
    .door-info {
      &__img {
        float: $ldirection;
        margin-#{$rdirection} : 10px;
      }
      &__name {
        font-weight: bold;
      }
      &__avail {
        margin : 10px 0 0 33px;
      }
      &__address {
        margin-#{$ldirection} : 33px;
        &-timing,
        .avail-info__phone {
          margin: 0;
        }
      }
      &__name--distance {
        opacity: 0.5;
      }
      &__direction {
        margin-#{$ldirection} : 33px;
        @media #{$medium-up} {
          width: 56%;
          margin-top: 20px;
        }
        &>p {
          margin-top: 0;
          white-space: nowrap;
        }
      }
    }
    .door-row {
      width: 92%;
      background-color: $color-gray-cs-callout;
      padding: 20px 10px;
      margin-bottom: 5px;
      &__btns--link {
        text-decoration: underline;
        font-weight: bold;
        font-size: 12px;
      }
    }
    .avail-info__msg {
      margin-bottom: 5px;
      color: $color-periwinkle-blue;
      &--not-avail {
        color: $color-red;
        .avail-info__msg--text {
          width: 100%;
        }
      }
      &--text {
        display: block;
        text-transform: uppercase;
        font-size: 12px;
      }
      .select_store_btn {
        height: 40px;
        background: $color-navy;
        color: $color-white;
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
        border: none;
        padding: 0 35px;
        margin-top: 24px;
        @media #{$medium-up} {
          float: $rdirection;
          width: 35%;
          padding: 0;
          margin-top: 0;
        }
      }
    }
    .no_results_found {
      width: 94%;
    }
  }
  .partial_results_found {
    margin-top: 26px;
    &-product {
      float: $ldirection;
      background: $color-lighter-gray;
      width: 100%;
      display: inline-block;
      margin: 2%;
      @media #{$medium-up} {
        width: 48%;
        margin: 10px 15px 10px 0;
      }
    }
    .bopis_sku_image {
      margin: 10px;
      width: 35%;
      float: $ldirection;
      .sku_img {
        width: 100%;
      }
    }
    .prod_info {
      width: 55%;
      float: $ldirection;
      margin-top: 10px;
    }
    .prod_avail_no {
      color: $color-red;
      padding-#{$rdirection}: 5px;
    }
    .prod_name {
      float: $ldirection;
      font-size: 24px;
      width: 100%;
    }
    .prod_shade {
      .shade_color {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        display: inline-block;
      }
      .shade_name {
        vertical-align: top;
      }
    }
    .continue-action-radio-container {
      padding: 2px;
      width: 100%;
      float: $ldirection;
      .radio_option {
        width: 100%;
        float: $ldirection;
        margin-bottom: 10px;
        font-size: 12px;
        input[type="radio"] {
          &~label {
            &:before,
            &:after {
              border-radius: 0;
            }
          }
        }
      }
    }
    &-header,
    .continue-action-header-text {
      display: none;
    }
    &-products {
      margin-bottom: 25px;
      float: $ldirection;
      width: 100%;
      @media #{$medium-up} {
        height: 230px;
        overflow: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $color-darker-gray;
        }
        &::-webkit-scrollbar-track {
          background-color: $color-lighter-gray;
        }
      }
    }
    .location-controls__btn--boris-ship {
      width: 100%;
      background: $color-navy;
      color: $color-white;
      font-weight: bold;
      font-size: 12px;
      @media #{$medium-up} {
        width: 40%;
        margin-top: 30px;
      }
    }
  }
}
#shipping-panel {
  #checkout_shipmethod {
    .delivery-to-use {
      .deliver-or-pickup {
        width: 100%;
        margin-bottom: 12px;
        padding-#{$ldirection}: 2px;
        @media #{$medium-up} {
          margin-#{$rdirection}: 10px;
        }
        input[type="radio"] {
          &~label {
            &:before,
            &:after {
              border-radius: 0;
            }
          }
        }
      }
    }
  }
  .bopis-panel {
    .bopis-title {
      width: 100%;
      font-family: $font_1;
      font-size: 21px;
      letter-spacing: normal;
      text-transform: capitalize;
      @media #{$medium-up} {
        font-family: $font_3;
        font-weight: normal;
        font-size: 22px;
      }
    }
    .store-links {
      display: block;
      float: $ldirection;
      width: 100%;
      .item {
        float: $ldirection;
        text-decoration: underline;
        text-transform: uppercase;
        width: 50%;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 12px;
      }
    }
    .bopis-shipping {
      .bopis-details {
        width: 100%;
        @media #{$medium-up} {
          width: 50%;
          float: $ldirection;
        }
        &>p {
          margin-bottom: 10px;
        }
        .bopis-working-hours {
          width: 100%;
          margin-bottom: 10px;
          display: block;
        }
        .store-name {
          font-weight: bold;
          font-size: 15px;
        }
        .store-address,
        .store-name {
          margin-bottom: 0;
        }
        .store-distance {
          opacity: 0.5;
        }
      }
    }
    #bopis_map {
      @media #{$medium-up} {
        width: 300px;
        height: 300px;
        float: $rdirection;
      }
    }
    .bopis-shipping-form {
      width: 100%;
    }
    .bopis-pickup-radio {
      width: auto;
      margin: 0 30px 10px 2px;
      input[type="radio"] {
        &~label {
          &:before,
          &:after {
            border-radius: 0;
          }
        }
      }
    }
    .bopis-pickup-by-radio {
      overflow: hidden;
    }
  }
}
.free-shipping-info-container {
  margin: 10px;
  h4 {
    margin: 10px 0;
  }
  .bopis-shipping-cost {
    &-header {
      border: 1px solid $color-input-border-grey;
      text-transform: uppercase;
      background-color: $color-gwp-background;
      font-weight: bold;
      &__list {
        display: inline-block;
        width: 48%;
        padding-#{$ldirection}: 10px;
        text-transform: uppercase;
        @media #{$small-only} {
          font-size: 14px;
        }
      }
    }
    &-methods {
      border: 1px solid $color-input-border-grey;
      border-top: none;
      &__list {
        display: inline-block;
        width: 48%;
        padding-#{$ldirection}: 10px;
        vertical-align: middle;
        text-transform: none;
        @media #{$small-only} {
          font-size: 14px;
        }
      }
    }
  }
  .details {
    text-align: center;
    margin: 15px 0;
    a {
      text-decoration: underline;
    }
  }
}
.bopis-info-container {
  text-align: center;
  h4 {
    font-weight: bold;
    margin: 10px 0;
  }
  h6 {
    font-weight: bold;
  }
  .bopis-additional-info {
    margin: 25px 0;
    text-decoration: underline;
  }
}